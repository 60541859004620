import React from 'react';
import { graphql } from 'gatsby';
import {
  ContentfulProgramsPage,
  ContentfulHeadingTextImage,
} from '../graphql-types';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Header from '../components/Product/Header';
import Steps from '../components/Product/Steps';
import Features from '../components/Product/Features';
import Programs from '../components/Product/Programs';
import PersonalizedContent from '../components/Product/PersonalizedContent';
import FAQ from '../components/Product/FAQ';

interface Props {
  pathContext: {
    locale: 'en' | 'de';
  };
  data: {
    programs: ContentfulProgramsPage;
    benefitsBoxes: { nodes: [{ benefitsBoxes: ContentfulHeadingTextImage[] }] };
  };
}

const Product: React.FC<Props> = ({
  pathContext: { locale },
  data: { programs },
}) => {
  return (
    <Layout isBlueFooter>
      <SEO lang={locale} title={programs.seoTitle} />
      <Header />
      <Steps />
      <Features />
      <Programs />
      <PersonalizedContent />
      <FAQ />
    </Layout>
  );
};

export const aboutPageQuery = graphql`
  query programsPage($locale: String!) {
    programs: contentfulProgramsPage(node_locale: { eq: $locale }) {
      seoTitle
      heroTitle
      heroText {
        childMarkdownRemark {
          rawMarkdownBody
          html
        }
      }
      chatBubbles {
        id
        heading
        shortText
        image {
          fixed(width: 40) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
      }
      video {
        url
        text
        isDisplayed
      }
      insurancesHeadline
      logos {
        id
        image {
          title
          fixed(width: 120) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
        isDisplayed
      }
      comparison {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }

      comparisonPrograms
      comparisonList {
        name
        description
        tracking
        programs
      }
      comparisonFooter {
        currency
        description
        callToAction {
          text
          url
          isDisplayed
        }
        policy
        policyDescription
      }
      isTeamSectionDisplayed
      scienceSection {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        callToAction {
          text
          url
          isDisplayed
        }
      }
      testimonialCta {
        text
        url
        isDisplayed
      }
      sliderSection {
        prefix
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        image {
          fluid: fluid(maxWidth: 500) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
    #
    benefitsBoxes: allContentfulProgramsPage(
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        benefitsBoxes {
          heading
          text {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          image {
            fluid: fluid(maxWidth: 638) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`;

export default Product;
