import React, { FC, useRef } from 'react';
import Container from '../Reimbursement/Container';
import {
  MonoFontLabel,
  OpenSansParagraph,
  RecoletaHeading,
} from '../common/typography';
import { markdownText } from '../../pages/doctors';
import Slider, { Settings } from 'react-slick';
import Rundown1 from '../../assets/svgs/product/img-program-rundown-1.svg';
import Rundown2 from '../../assets/svgs/product/img-program-rundown-2.svg';
import Rundown3 from '../../assets/svgs/product/img-program-rundown-3.svg';
import Rundown4 from '../../assets/svgs/product/img-program-rundown-4.svg';
import SliderArrow from '../common/slider/SliderArrow';
import { css } from '@emotion/core';
import theme from '../../styles/theme';
import styled from '@emotion/styled';
import presets from '../../styles/presets';
import { useIntl } from 'react-intl';

interface ProgramCardProps {
  src: string;
  prefix: string;
  title: string;
  description: string;
}

const MobileArrows = styled.div(css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-left: 20px;

  ${presets.lg} {
    display: none;
  }
`);

const ProgramCard: FC<ProgramCardProps> = ({
  src,
  prefix,
  title,
  description,
}) => {
  return (
    <Container
      css={{
        maxWidth: 400,
      }}
    >
      <img
        src={src}
        alt={title}
        css={{
          width: '100%',
          height: 'auto',
          objectFit: 'contain',
          marginBottom: 20,
        }}
      />
      <MonoFontLabel
        css={{
          fontWeight: 700,
          letterSpacing: '2px',
          lineHeight: '18px',
          color: '#00000080',
          marginBottom: 5,
        }}
      >
        {prefix}
      </MonoFontLabel>
      <RecoletaHeading
        css={{
          fontSize: '24px',
          fontWeight: 600,
          maxWidth: 400, // will be different per section
        }}
      >
        {title}
      </RecoletaHeading>
      <OpenSansParagraph
        css={{
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '25px',
          marginTop: '1rem',
        }}
      >
        {markdownText(description)}
      </OpenSansParagraph>
    </Container>
  );
};

const Programs: FC = () => {
  const intl = useIntl();
  const ref = useRef<Slider>(null);

  const dataObj = [
    {
      src: Rundown1,
      prefix: intl.formatMessage({
        id: 'product.page.programs.item1.prefix',
        defaultMessage: 'Vor Programmstart',
      }),
      title: intl.formatMessage({
        id: 'product.page.programs.item1.title',
        defaultMessage: 'Vorbereitungsphase & Gesundheits-Fragebogen',
      }),
      description: intl.formatMessage({
        id: 'product.page.programs.item1.text',
        defaultMessage:
          'Zu Beginn des Programms stehen dir in der sogenannten Vorbereitungsphase einführende Artikel rund um das Programm sowie ein ausführlicher Gesundheits-Fragebogen zur Verfügung.\n\n Der Gesundheits-Fragebogen bildet den Grundstein für dein personalisiertes Programm: Basierend auf deinen Angaben im Fragebogen wird in der nächsten Phase die für dich passende Modulkombination aus unterschiedlichen Therapiebereichen zusammengestellt.',
      }),
    },
    {
      src: Rundown2,
      prefix: intl.formatMessage({
        id: 'product.page.programs.item2.prefix',
        defaultMessage: '1. - 12. Woche',
      }),
      title: intl.formatMessage({
        id: 'product.page.programs.item2.title',
        defaultMessage:
          'Personalisierte Therapiephase durch optimierte Modulwahl',
      }),
      description: intl.formatMessage({
        id: 'product.page.programs.item2.text',
        defaultMessage:
          'Neben dem Modul Basiswissen wirst du abhängig von deinen Angaben im Gesundheits-Fragebogen zusätzlich durch die Low-FODMAP-Diät, durch ein Selbsthilfe-Manual aus der kognitiven Verhaltenstherapie oder durch audio-geführte Hypnose-Einheiten geleitet. Dabei können je nach Angaben 1 - 3 Module gleichzeitig freigeschaltet werden.',
      }),
    },
    {
      src: Rundown3,
      prefix: intl.formatMessage({
        id: 'product.page.programs.item3.prefix',
        defaultMessage: 'alle 4 Wochen',
      }),
      title: intl.formatMessage({
        id: 'product.page.programs.item3.title',
        defaultMessage: 'Regelmäßige Verlaufskontrolle über Folge-Fragebogen',
      }),
      description: intl.formatMessage({
        id: 'product.page.programs.item3.text',
        defaultMessage:
          'Zusätzlich erfolgt alle 4 Wochen eine optionale aber ausdrücklich empfohlene Verlaufskontrolle deines Behandlungsfortschritts statt. Der Folge-Fragebogen ist kürzer als der Gesundheits-Fragebogen, den du zu Beginn ausfüllst. Hier wird vor allem nach der Entwicklung deiner Beschwerden und deiner Lebensqualität gefragt.\n\nOb du dich verbessert hast, siehst du auf einen Blick in der App in Form einer graphischen Auswertung. Außerdem kannst du deinen persönlichen Fortschrittsbericht exportieren.',
      }),
    },
    {
      src: Rundown4,
      prefix: intl.formatMessage({
        id: 'product.page.programs.item4.prefix',
        defaultMessage: 'nach 12 Wochen',
      }),
      title: intl.formatMessage({
        id: 'product.page.programs.item4.title',
        defaultMessage: 'Ende des 12-Wochen-Programmzyklus',
      }),
      description: intl.formatMessage({
        id: 'product.page.programs.item4.text',
        defaultMessage:
          'Wenn du im zeitlich empfohlenen Rahmen unseres Programms geblieben bist, hast du alle Inhalte nach 12 Wochen durchlaufen, und dir geht es hoffentlich schon viel besser.\n\nDu kannst den Nutzungszeitraum jederzeit per Folgeverschreibung verlängern, um so weiter von den bereitgestellten Inhalten, den Rezepten, den Tagebuch- & Trackingfunktionen, und mehr zu profitieren und deine neuen Lebensgewohnheiten zu festigen.',
      }),
    },
  ];

  const sliderSettings: Settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    centerPadding: '0px',
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    nextArrow: <SliderArrow position="right" />,
    prevArrow: <SliderArrow position="left" />,
    responsive: [
      {
        breakpoint: theme.breakpoints.lg,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          draggable: true,
        },
      },
    ],
  };

  const settingsStyle = css`
    .slick-next,
    .slick-prev {
      margin: -60px;
    }

    .slick-next:before,
    .slick-prev:before {
      content: '';
    }
  `;

  return (
    <Container
      css={{
        maxWidth: 860,
        marginTop: '5rem',
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <RecoletaHeading
          css={{
            fontSize: '32px',
            fontWeight: 500,
            lineHeight: '38px',
            textAlign: 'center',
          }}
        >
          {markdownText(
            "Der <b style='color: #489F9D'>Ablauf</b> deines <br/> 12-Wochen-Programms"
          )}
        </RecoletaHeading>
        <MobileArrows>
          <SliderArrow
            position="left"
            onClick={() => ref.current.slickPrev()}
          />
          <SliderArrow
            position="right"
            onClick={() => ref.current.slickNext()}
          />
        </MobileArrows>
      </div>

      <Slider
        ref={ref}
        {...sliderSettings}
        css={[
          settingsStyle,
          {
            cursor: 'grab',
            marginTop: '1rem',
          },
        ]}
      >
        {dataObj.map((data, index) => (
          <ProgramCard
            key={index}
            src={data.src}
            prefix={data.prefix}
            title={data.title}
            description={data.description}
          />
        ))}
      </Slider>
    </Container>
  );
};

export default Programs;
